<template>
	<div class="page">
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-select v-model="searchParams.type" style="margin-right: 10px;" placeholder="交易类型" size="small">
				<el-option label="全部" value="1"></el-option>
				<el-option label="设备续费" value="2"></el-option>
				<el-option label="余额充值" value="3"></el-option>
			</el-select>
			<el-input size="small" style="width: 300px;margin-right: 10px;" placeholder="请输入设备号"
				v-model="searchParams.deviceSn">
				<template #prepend>设备号</template>
			</el-input>
			<el-input size="small" style="width: 300px;margin-right: 10px;" placeholder="请输入微信签名"
				v-model="searchParams.openId">
				<template #prepend>微信签名</template>
			</el-input>
			<el-button @click="search" size="small">搜索</el-button>
			<el-button type="primary" @click="downloadXlsx" size="small">导出xlsx</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table v-loading="loading" size="small" header-cell-class-name="tableHeader" :data="listData" border
				:max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed prop="tradeNo" label="订单号">
				</el-table-column>
				<el-table-column label="微信签名">
					<template #default="scope">
						<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ scope.row.openid }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="sum" label="成交金额">
				</el-table-column>
				<el-table-column prop="createTime" label="交易日期">
				</el-table-column>
				<el-table-column prop="type" label="交易类型" width="120">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template #default="scope">
						<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; text-align: right;">
				<el-pagination v-model:currentPage="currentPage" :page-sizes="[30]" :page-size="30"
					layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<div @click="cancel" class="device-info-card" v-show="showInfo">
			<el-card class="info-card" @click.stop="">
				<el-descriptions size="mini" title="订单信息">
					<el-descriptions-item label="订单号">{{nowItem.tradeNo}}</el-descriptions-item>
					<el-descriptions-item label="交易时间">{{nowItem.createTime}}</el-descriptions-item>
					<el-descriptions-item label="订单类型">{{nowItem.type}}</el-descriptions-item>
					<el-descriptions-item label="成交金额">{{nowItem.sum}} 元</el-descriptions-item>
					<el-descriptions-item label="微信签名">{{nowItem.openid}}</el-descriptions-item>
				</el-descriptions>
				<el-descriptions border size="mini" :column="2" :key="index"
					v-for="(item,index) in nowItem.devicePayInfos" style="margin-top: 20px;"
					:title="index == 0 ? '订单详情' : ''">
					<el-descriptions-item label="卡号">{{item.kaHao}}</el-descriptions-item>
					<el-descriptions-item label="设备号">{{item.deviceSn}}</el-descriptions-item>
					<el-descriptions-item v-if="nowItem.type == '设备续费'" label="续费前到期时间">{{item.beforeTime}}
					</el-descriptions-item>
					<el-descriptions-item v-if="nowItem.type == '设备续费'" label="续费后到期时间">{{item.afterTime}}
					</el-descriptions-item>
					<el-descriptions-item v-if="nowItem.type == '余额充值'" label="充值前余额">
						{{item.beforeSum == null ? 0 : item.beforeSum}}
					</el-descriptions-item>
					<el-descriptions-item v-if="nowItem.type == '余额充值'" label="续费后余额">
						{{item.afterSum == null ? 0 : item.afterSum}}
					</el-descriptions-item>
				</el-descriptions>
			</el-card>
		</div>

	</div>
</template>

<script>
	import {
		selectList,
		exportPayLog,
	} from '../../api/pay.js'
	export default {
		data() {
			return {
				nowItem: {},
				showInfo: false,
				tableHeight: 100,
				currentPage: 1,
				count: 0,
				limit: 30,
				listData: [],
				searchParams: {},
				loading:false
			}
		},

		mounted: function() {
			this.tableInit()
			this.list()
		},

		methods: {
			handleClick: function(item) {
				this.showInfo = true
				this.nowItem = item
				console.log(item)
			},

			/**
			 * 导出所有交易记录
			 */
			downloadXlsx: function() {
				exportPayLog().then(res => {
					var blob = new Blob([res.data])
					var downloadElement = document.createElement('a');
					var href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					downloadElement.download = '用户数据.xlsx'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				})
			},

			search: function() {
				var that = this
				that.currentPage = 1
				that.loading = true
				selectList({
					type:that.searchParams.type,
					deviceSn:that.searchParams.deviceSn,
					openId:that.searchParams.openId,
					limit: that.limit,
					startPage: (that.currentPage - 1)*that.limit
				}).then(res => {
					that.loading = false
					that.listData = res.data.data.list
					that.count = res.data.data.count
				})
			},

			list: function() {
				var that = this
				that.loading = true
				selectList({
					limit: that.limit,
					startPage: (that.currentPage - 1)*that.limit
				}).then(res => {
					that.loading = false
					that.listData = res.data.data.list
					that.count = res.data.data.count
				})
			},

			handleCurrentChange: function(data) {
				this.currentPage = data
				this.list()
			},

			tableInit: function() {
				var that = this
				let tableBox = this.$refs.tableBox
				this.tableHeight = tableBox.offsetHeight - 60
				setTimeout(function() {
					tableBox.children[0].style.maxHeight = that.tableHeight + "px"
				}, 0)
			},

			cancel: function() {
				this.showInfo = false
			},
		}


	}
</script>

<style scoped="scoped">
	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}

	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.device-info-card {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .5);
		z-index: 99;
	}

	.info-card {
		width: 800px;
		padding: 30px;
		position: absolute;
		top: 5vh;
		left: 50%;
		margin-left: -400px;
		transition: 1s;
		z-index: 2;
	}
</style>
