import request from '../util/requset.js'

export function selectList(params){
	return request.get("/pay/searchPayLogList",{
		params:params
	})
}

export function getCount(){
	return request.get("/pay/getCount")
}

export function exportPayLog(){
	return request.post("/pay/export",{},{responseType: 'blob'})
}

export function getXufeiList(params){
	return request.get("/pay/getXuFeiList",{
		params:params
	})
}


export function getXuFeiCount(params){
	return request.get("/pay/getXuFeiCount",{
		params:params
	})
}


export function exportXuFeiData(){
	return request.get("/pay/exportXuFeiData")
}